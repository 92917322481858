@font-face {
  font-family: 'Oracle';
  src:
    url('../public/fonts/ABCOraclePlusVariable.woff2') format('woff2-variations'),
    url('../public/fonts/ABCOraclePlusVariable.woff') format('woff');
}

@font-face {
  font-family: "Noto Sans Regular";
  src: url("../public/fonts/NotoSans-Regular.woff2") format("woff2"), url("../public/fonts/NotoSans-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../public/fonts/NotoSansJP-VariableFont_wght.woff2") format("woff2-variations");
}

@font-face {
  font-family: "Noto Sans KR";
  src: url("../public/fonts/NotoSansKR-VariableFont_wght.woff2") format("woff2-variations");
}

@font-face {
  font-family: "Noto Sans SC";
  src: url("../public/fonts/NotoSansSC-VariableFont_wght.woff2") format("woff2-variations");
}

@font-face {
  font-family: "Noto Sans TC";
  src: url("../public/fonts/NotoSansTC-VariableFont_wght.woff2") format("woff2-variations");
}