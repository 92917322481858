@import "./fonts.css";
@import "./variables.css";
@import "./preflight.css";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-size: 10px;

  /* REM SCALING */
  min-height: 0;
  --rem-scale-unclamped: calc((10 / var(--rem-scale-design-width)) * var(--rem-scale-scale-factor) * 100vw);
  --rem-scale-bounds-min: max(1px, var(--rem-scale-scale-min) * var(--rem-scale-scale-factor) * 10px);
  --rem-scale-bounds-max: calc(10 * var(--rem-scale-scale-max) * var(--rem-scale-scale-factor) * 1px);
  --rem-scale-viewport-excess: max(0px, 100vw - var(--rem-scale-design-width) * 1px);
  --rem-scale-viewport-half-excess: calc(var(--rem-scale-viewport-excess) * 0.5);
  font-size: clamp(var(--rem-scale-bounds-min), var(--rem-scale-unclamped), var(--rem-scale-bounds-max));

}

body {
  position: relative;
  background: linear-gradient(150deg, #FCFCFD 15.14%, #F7F3F5 80.54%);
}

a {
  color: inherit;
  text-decoration: none;
}

#Stage {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ios, .ios body, .ios #Stage {
  overflow: visible;
}

.ios, .mob {
  position: relative;
  height: 100vh;
}

.GLA11y {
  position: absolute;
  width: 0;
  height: 100%;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

#Stage {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
}

#Stage * {
  position: absolute;
}

#Stage, #Stage * {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: none;
}

#Stage * input, #Stage * textarea {
  -moz-user-select: auto;
  -webkit-user-select: auto;
  -o-user-select: auto;
  -ms-user-select: auto;
  -webkit-tap-highlight-color: auto;
}

#Stage br, #Stage span {
  position: relative;
}

.feature-detects {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 100vh;
  clip: rect(0 0 0 0);
}

@supports (--css: variables) and (padding: env(safe-area-inset-bottom)) {
  .feature-detects {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.subheading-1 {
  font-size: 3.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.68px;
  margin-bottom: 1.5em;

  @media (max-width: 768px) {
    margin-bottom: var(--spacing-sm);
  }
}

.mobile-only {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.desktop-only {
  @media (max-width: 768px) {
    display: none;
  }
}

#nprogress .bar {
  height: 5px !important;
  background: var(--color-nprogress) !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px var(--color-nprogress), 0 0 5px var(--color-nprogress) !important;
}

#nprogress .spinner-icon {
  border-top-color: var(--color-nprogress) !important;
  border-left-color: var(--color-nprogress) !important;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.collapsed-main-content {
  min-height: unset !important;
}